ul.mde-suggestions {
  position: absolute;
  min-width: 180px;
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
  cursor: pointer;
  background: #fff;
  border: 1px solid #c8ccd0;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(27, 31, 35, 0.15); }
  ul.mde-suggestions li {
    padding: 4px 8px;
    border-bottom: 1px solid #e1e4e8; }
    ul.mde-suggestions li:first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
    ul.mde-suggestions li:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px; }
    ul.mde-suggestions li:hover, ul.mde-suggestions li[aria-selected=true] {
      color: white;
      background-color: #0366d6; }
